import { XMarkIcon } from '@heroicons/react/20/solid'
import { useState } from 'react';
import { NavLink } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";


export default function BannerFreebie() {
    const [open, setOpen] = useState(true)
    if(!open){
        return null;
    }
  return (
    <div className="sticky top-20 z-40 flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
     
          <strong className="font-semibold">🎁 LIMITED TIME FREE Story Offer!</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            
          </svg>
          {/*Sign Up Now! Get 1 Credit Free to Craft Your First Personalized Story! No Credit Card Required! */}
          Sign Up Now & Create Your First Personalized Children's Story for Free! No Credit Card Required! 
      </p>
      <NavLink to="/signup"   className="rounded-md bg-yellow-400 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-yellow-300 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-400"
      >Try Now! </NavLink>
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={() => {setOpen(false)}}>
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}