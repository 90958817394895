import PageNav from "./components/PageNav";
import { NavLink } from "react-router-dom";
import { UseUserAuth } from './context/UseAuthContext';
import React, {  useEffect, useState } from "react";
import Pricing from './Pricing';
import Contact from "./Contact";
import About from './About';
//import CreationInstructions from './CreationInstructions';
import Banner from './Banner'; 
import BannerFreebie from "./BannerFreebie";
import { Helmet } from 'react-helmet';

function Homepage() {
  //const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const pricingRef = React.createRef();
  const [showBanner, setShowBanner] = useState(false);
  const [showFreebie, setShowFreebie] = useState(false);
  const structuredData =  {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "MyTaleMaker",
    "url": "https://www.mytalemaker.com",
    "description": "Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations."
  }
  useEffect(() => {
    async function fetchData(){
    try {

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/public/getCountry`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
        }
      );

      const data = await response.json();
      if (data.status === 403){
        setShowBanner(true);
        setShowFreebie(false);
      //  alert('We are sorry. Currently, the beta launch is not available in your country. We hope to see you very soon!')
      }
      else{ if(data.status === 200) {
        setShowBanner(false);
        setShowFreebie(true);
      }
      }
    } catch (error) {
      console.error("Error fetching country information:", error);
    }
  };
      // Call fetchData to fetch country information
  fetchData();
    // Handle URL parameters for scrolling
    const urlParams = new URLSearchParams(window.location.search);
    const section = urlParams.get("section");

    if (section === "pricing") {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      // Optional: Handle the case where no section parameter is provided
      // (e.g., do nothing, scroll to the top, etc.)
      // window.scrollTo(0, 0); // Scroll to the top of the page
    }
  }, []);
  return (
    <div className="bg-white">
      <Helmet>
        <title>MyTaleMaker</title>
        <meta property="og:site_name" content="MyTaleMaker" />
        <meta name="description" content="Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations." />
        <meta property="og:title" content="MyTaleMaker" />
        <meta property="og:description" content="Create personalized stories for children in minutes using AI. Tailored to your child's imagination with beautiful illustrations." />
        <meta name="application-name" content="MyTaleMaker" />
        <meta property="og:url" content="https://www.mytalemaker.com" />
        <meta property="og:image" content="https://static.mytalemaker.com/logo-icon.png" />
        <link rel="canonical" href="https://www.mytalemaker.com" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <PageNav classname= "absolute inset-x-0 top-0 z-50"/>  
      {showBanner && <Banner />} {/*onConfirm={() => setShowBanner(false)} */}
      {showFreebie && <BannerFreebie/>} 
      <SecFunc />    
      <About/>
     {/* <CreationInstructions/> */}
      <div ref={pricingRef}> 
        <Pricing />
      </div> 
      
      <Faqs />
      <Contact/>
    </div>
  );
}

function SecFunc(){
  const {  user } = UseUserAuth();
  //console.log("create user", user)
  return( <main>
    <div className="relative isolate">
      <svg
        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
      </svg>
      <div
        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
        aria-hidden="true"
      >
        <div
          className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
          }}
        />
      </div>
      <div className="overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              MyTaleMaker : Instantly Create Personalized Children's Stories with AI
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
              Engage your child with beautifully illustrated custom stories that teach life lessons, build good habits, and spark a love for reading. Entertain, inspire, and create lasting family memories!
              {/*Teach life lessons, build good habits, and spark a love for reading through fun, personalized stories that kids find relatable. Entertain, inspire, and create lasting family memories! */}
             
              </p>
              <div className="mt-10 flex items-center gap-x-6">
              <NavLink to={user ? '/create' : "/login"}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </NavLink>
                <NavLink to="/gallery" className="text-sm font-semibold leading-6 text-gray-900">
                  Samples <span aria-hidden="true">→</span>
                </NavLink>
              </div>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
              <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                <div className="relative">
                  <img
                  src="https://static.mytalemaker.com/girl-running-compressed.jpeg"
                    alt="girl running in a story"
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                <div className="relative">
                  <img
                  src="https://static.mytalemaker.com/boy-smiling-compressed.jpeg"
                    alt="boy smiling in a story"
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative">
                  <img
                  src="https://static.mytalemaker.com/kids-with-dinos-compressed.jpeg"
                    alt="kids with dinosaurs in a story"
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                <div className="relative">
                  <img
                  src="https://static.mytalemaker.com/girl-with-flowers-compressed.jpeg"
                    alt="girl with flowers in a story"
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative">
                  <img
                  src="https://static.mytalemaker.com/girl-in-classroom-compressed.jpeg"
                    alt="girl in a classroom in a story"
                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>)
}


const faqs = [{
  id:1 ,
  question: "What is the purchasing process for these books?",
  answer:
    "Currently, we offer digital versions of our books. Upon purchase of the necessary credits, customers can build their book. Books will be accessible in your account, and you can either download them in PDF format for your convenience or read them online.",
  },
  {
    id:2 ,
    question: "What sets your storybooks apart from others?",
    answer:
      "Unlike traditional books, our AI creates unique, personalized stories in minutes! Each book is a treasured keepsake with high-quality illustrations and text, all at a great price. Plus, we offer unmatched customization with generous AI-powered image revisions to ensure your complete satisfaction.",
    }
    ,
    {
      id: 3,
      question: "What options do you provide for customization and personalization in the storybooks?",
      answer:
        "Utilizing our intuitive platform, users can customize the protagonist's name, inject their creative touch into the narrative, or select moral values to enrich the tale. Additionally, our ongoing innovation introduces a diverse range of character appearances, ensuring each child discovers a representation that speaks to them. Upon logging in and visiting our creation page, users can explore detailed steps to personalize their storybooks.",
      }
      ,
  {
    id:4 ,
    question: "What age range are these storybooks suitable for?",
    answer:
    "Our personalized storybooks are designed to enchant the hearts of young readers aged two to seven. We offer different text formats tailored to specific age groups: for preschoolers (ages 2-4), the text is shorter and simpler, making it perfect for early developmental stages. For early readers (ages 5-7), the text is more detailed and complex, suited to their advancing reading skills."
  },
  {
  id: 5,
  question: "How long does it take to receive the e-book?",
  answer:
    "After the customer submits their input, it usually takes about 2 minutes to generate an e-book. If a customer asks AI to re-draw images, it may take additional few minutes. ",
},{
  id:6 ,
  question: "Can I create a free book before purchasing?",
  answer:"Absolutely! As a special thank you for supporting our woman-owned small business, we offer your first personalized e-book for free upon sign up. After your first book, we keep our e-book prices around the cost of a cup of coffee to ensure affordability. Additionally, we regularly add new stories to our free gallery for you to enjoy. Your support helps us thrive and allows us to continue sharing this unique experience with families. Thank you for being part of our journey!"
  }]

function Faqs() {
  return (
    <div className="bg-white"> 
    <div className="mx-auto max-w-7xl px-6 py-6 sm:py-10 lg:px-8">
      <div className="mx-auto max-w-2xl text-center ">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2> {/* */}
        <p className="mt-6 text-base leading-7 text-gray-600">{/* */}
          Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
          <a href="mailto: contact@mytalemaker.com" className="font-semibold text-indigo-600 hover:text-indigo-500"> {/* */}
            sending us an email
          </a>{' '}
          and we’ll get back to you as soon as we can.
        </p>
      </div>
      <div className="mt-20">
        <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
          {faqs.map((faq) => (
            <div key={faq.id}>
              <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt> {/* */}
              <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd> {/* */}
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
  );
}


export default Homepage;
