/* eslint-disable react/no-unescaped-entities */
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { UseUserAuth } from "./context/UseAuthContext";
import AlertCredit from "./AlertCredit";
import Modal from "./Modal";

import MissingInput from "./MissingInputDialogueBox";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

function Create() {
  const [selectedStoryType, setSelectedStoryType] = useState("Essential");
  const [credits, setCredits] = useState(0);
  const { user } = UseUserAuth();

    // Fetch credits in parent component
  const fetchCredits = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/getNumCredits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
        }
      );

      if (!response.ok) {
        console.warn("Something went wrong with the credit check request.");
        return;
      }

      const result = await response.json();
      setCredits(parseInt(result));
    } catch (error) {
      console.error("Error fetching credits:", error);
    }
  };

    // Function to send email verification status to the backend
    const sendEmailVerificationStatus = async () => {
      try {
        const idToken = await user.getIdToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/user/usersEmailVerificationInfo`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: idToken,
            },
            body: JSON.stringify({ emailVerified: user.emailVerified }),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to update email verification status");
        } else {
          console.log("Email verification status updated successfully");
          localStorage.setItem('emailVerifiedStatus', 'true');  // Store in localStorage
        }
      } catch (error) {
        console.error("Error updating email verification status:", error);
      }
    };
  
    // Call sendEmailVerificationStatus when component mounts and user is verified
    useEffect(() => {
      const hasUpdatedEmailVerified = localStorage.getItem('emailVerifiedStatus') === 'true';
      console.log("hasUpdatedEmailVerified - create", hasUpdatedEmailVerified);
      if (user && user.emailVerified && !hasUpdatedEmailVerified) {
        console.log("updating create")
        sendEmailVerificationStatus();
      }if (user) {
      fetchCredits(); // Fetch credits when component mounts and user exists
    }
    }, [user]);

  //const [credits, setCredits] = useState(0);
  if (user.emailVerified) {
    return (
      <div>
        <PageNav className="absolute inset-x-0 top-0 z-50" />
        <Heading credits={credits} />
        {/*<Generate /> */}
        <StoryOptionSelector
          setSelectedStoryType={setSelectedStoryType}
          credits={credits}
          
        />
        
      </div>
    );
  }

  return (
    <div>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      <Heading credits={credits} />
      <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
        <div
          role="list"
          className="mt-8 max-w-2xl space-y-8 text-gray-800 text-justify"
        >
          <div className="flex gap-x-3">
            <ExclamationCircleIcon
              className="mt-1 h-5 w-10 flex-none text-indigo-600"
              aria-hidden="true"
            />
            <span>
              Please verify your email address first. An email verification link has been sent to your email. If you have verified your
              email, please refresh this page.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Heading({ credits }) {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }

    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};



useEffect(() => {
  if (user) {
    fetchUserName();
  }
}, [user]);

return (
  <div className="flex flex-col items-center gap-2 p-4 mt-3 text-center">
      <div className="text-lg font-semibold text-indigo-600">
        Welcome <br />
        {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
      </div>
      <div className="text-sm text-gray-600 mt-4">
        Your Credit Balance: <span className="font-semibold">{credits}</span>
      </div>
    </div>
  );
}



function StoryOptionSelector({ setSelectedStoryType, credits }) {
    const [selectedStoryTypeLocal, setSelectedStoryTypeLocal] = useState('Essential');
    const [alertVisible, setAlertVisible] = useState(false);
   // const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isMissingInputOpen, setIsMissingInputOpen] = useState(false);
    const navigate = useNavigate(); // Initialize navigate

      // Set initial story type based on credits
    useEffect(() => {
    if (credits >= 2) {
      setSelectedStoryTypeLocal('Enhanced');
      setSelectedStoryType('Enhanced');
      }
    }, [credits]);

    const handleOptionChange = (event) => {
      setSelectedStoryTypeLocal(event.target.value); // Update local state
      setSelectedStoryType(event.target.value);
    };
   
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Determine the number of credits based on the selected option
      const requiredCredits = selectedStoryTypeLocal === 'Essential' ? 1 : 2;
  
  
      // Check if the user has the necessary credits for story creation
      if (credits < requiredCredits || isNaN(credits)) {
        setAlertVisible(true); // Show alert if not enough credits
        return; // Prevent further execution
      }
  
      // Proceed with the next steps if sufficient credits are available
    //  setIsConfirmationOpen(true); // Display confirmation modal or proceed with next step
       // Navigate to the appropriate page based on selected option and pass selectedOptionLocal as state
    console.log("Selected story type:", selectedStoryTypeLocal);
    if (selectedStoryTypeLocal === "Essential") {
        navigate("/createessential", { state: { selectedStoryType: selectedStoryTypeLocal } }); // Pass state
      } else if (selectedStoryTypeLocal === "Enhanced") {
        navigate("/createenhanced", { state: { selectedStoryType: selectedStoryTypeLocal } }); // Pass state
      }

};
    
return (
  <div className="flex justify-center "> {/*bg-slate-50 */}
    <div className="max-w-md mb-10">
      <h2 className="flex gap-x-3 mt-6 text-2xl font-bold tracking-tight text-gray-900 text-center">
        <span>First step : Select a story type</span>
      </h2>

      <div className="bg-white p-6 shadow-lg rounded-lg mt-8">
        <div className="sm:col-span-6">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Select Story Type
          </label>
          <div className="mt-2 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 p-2">
            {/* Essential Option */}
            <label className="flex items-center mb-2">
              <input
                type="radio"
                value="Essential"
                checked={selectedStoryTypeLocal === "Essential"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              />
              <span className="ml-2 text-sm font-medium leading-6 text-gray-900">
                Essential - Quick Story (1 Credit)
              </span>
            </label>

            {/* Enhanced Option */}
            <label className="flex items-center">
              <input
                type="radio"
                value="Enhanced"
                checked={selectedStoryTypeLocal === "Enhanced"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              />
              <span className="ml-2 text-sm font-medium leading-6 text-gray-900">
                Enhanced - Greater Creative Control (2 Credits)
              </span>
            </label>
          </div>
        </div>

        {/* Submit button */}
        <button
          onClick={handleSubmit}
          className="mt-4 px-4 py-2 w-full bg-indigo-600 text-white rounded-md text-center font-semibold"
        >
          Submit
        </button>

        
        <p className="mt-2 text-center text-sm text-gray-600">
          Selected option: <strong>{selectedStoryTypeLocal}</strong>
        </p>

        {/* Modal for insufficient credits */}
        <Modal isOpen={alertVisible} onClose={() => setAlertVisible(false)}>
          <AlertCredit />
        </Modal>

        {/* Show the confirmation dialog if it's open */}
        {isMissingInputOpen && (
          <MissingInput
            onConfirm={() => setIsMissingInputOpen(false)} // Just close the dialog on confirm
          />
        )}
      </div>
    </div>
  </div>
);
}

export default Create;