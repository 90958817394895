import PageNav from "./components/PageNav";
import { useNavigate } from 'react-router-dom';
import { generateSlug } from './utils';

const posts = [
  {
    id: 0,
    title: 'Craft Perfect Stories: Introducing Two New Story Types for Every Need',
    href: '#',
    description:"MyTaleMaker introduces two new story types designed to cater to different storytelling needs. The Essential option offers a quick and simple way to create engaging stories, while the Enhanced version provides greater customization for a more personalized experience. Both options help users craft meaningful stories for children, whether for learning or fun.",
    imageUrl:  'https://static.mytalemaker.com/boy_parents_tablet.jpg',
      alt : "boy-parents",
    date: 'Dec 16, 2024',
    datetime: '2024-12-16',
    category: { title: "Parenting, Storytelling, Personalized Storybooks, Children's Book", href: '#' },
 
  },
  {
    id: 1,
    title: 'Helping Children Navigate Big Emotions with Personalized Stories',
    href: '#',
    description:"Childhood is a time of intense emotions. From the joy of discovering new things to the sadness of losing a toy, children experience a wide range of feelings. Helping them understand and manage these emotions is a crucial part of their development. Personalized stories can be an invaluable tool in this journey.",      
    imageUrl:
      'https://static.mytalemaker.com/boy-fear.png',
      alt : "boy-fear",
    date: 'Aug 15, 2024',
    datetime: '2024-08-15',
    category: { title: "Parenting, Child Development, Storytelling, Personalized Storybooks, Children's Book", href: '#' },
 
  },
  {
    id: 2,
    title: 'Empowering Young Minds: The Importance of Social and Emotional Learning',
    href: '#',
    description:"Social and Emotional Learning is foundational to a child's overall well-being and success. It equips them with the tools to navigate life's challenges and build meaningful connections with others.",      
    imageUrl:
      'https://static.mytalemaker.com/papa-daughter.png',
      alt : "father and daughter",
    date: 'July 30, 2024',
    datetime: '2024-07-30',
    category: { title: "Parenting, Social And Emotional Learning, Child Development, Storytelling, Personalized Storybooks, Children's Book", href: '#' },
 
  },
  {
    id: 3,
    title: 'Innovative Ways to Use Personalized Storybooks for Learning',
    href: '#',
    description:"Personalized storybooks do more than just place your child at the heart of an engaging tale; they are also potent educational tools that can make learning enjoyable and interactive. Here are some inventive methods to incorporate personalized storybooks into your child's educational experience.",      imageUrl:
      'https://static.mytalemaker.com/mom-baby2.jpg',
    alt:"mom and baby",
    date: 'July 13, 2024',
    datetime: '2024-07-13',
    category: { title: "Parenting, Storytelling, Personalized Storybooks, Children's Book", href: '#' },
  },
    {
      id: 4,
      title: 'From Pages to Heart: The Impact of Personalized Storybooks on Kids',
      href: '#',
      description:
        'Imagine the joy on your child’s face as they discover themselves at the heart of their own adventure, their name woven seamlessly into a tale. Remember that time you read them their favorite story, their eyes wide with wonder? Personalized storybooks take that magic a step further, creating an immersive experience that sparks laughter, curiosity, and a deep emotional connection.',
      imageUrl:
        'https://static.mytalemaker.com/family_reading.jpg',
      alt:"family reading",
      date: 'July 6, 2024',
      datetime: '2024-07-06',
      category: { title: "Parenting, Storytelling, Personalized Storybooks, Children's Book" , href: '#' },
    }

  ]
  
  export default function Blog() {
    const navigate = useNavigate();

    function handleClickBlogPost(e, id){
      try{
        const post = posts.find(post => post.id === id);
        if (post) {
            const slug = generateSlug(post.title); // Generate slug dynamically
            navigate(`/blog/${slug}`);
        } else {
            console.error('Error loading blog post');
        }
    } catch(error){
        console.error("Error in sending blog", error);
    }  
    }
    return (
        <> 
        <PageNav className="absolute inset-x-0 top-0 z-50"/>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" style={{fontSize: "30px", lineHeight: "36px"}}>From the blog</h2>
            <p className="mt-2 text-lg leading-8 text-gray-500" style={{fontSize: "16px", lineHeight: "24px"}}>
              Let's explore new ways of parenting!
            </p>
            <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
              {posts.map((post) => (
                <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                  <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                    <img
                      src={post.imageUrl}
                      alt={post.alt}
                      className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                    />
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div>
                    <div className="flex items-center gap-x-4 text-xs">
                      <time dateTime={post.datetime} className="text-gray-500">
                        {post.date}
                      </time>
                      <a
                        href={post.category.href}
                        className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100" 
                      >
                        {post.category.title}
                      </a>
                    </div>
                    <div className="group relative max-w-xl">
                      <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a onClick={(e) => handleClickBlogPost(e, post.id)}>
                          <span className="absolute inset-0" style={{fontSize: "16px", lineHeight: "24px"}} />
                          {post.title}
                      </a>
                      </h3>
                      <p className="mt-5 text-gray-700" style={{ fontSize: "16px", lineHeight: "24px" }}>{post.description}</p>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  