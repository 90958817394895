import { useState } from "react";
import { HeartIcon } from "@heroicons/react/20/solid";
import SubscriptionAlert from "./SubscriptionAlert";
import { useNavigate } from "react-router-dom";

const social = [
  {
    name: "Instagram",
    href: "https://www.instagram.com/mytalemaker/",
    text: "Follow us at:",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [subscriptionAlert, setSubscriptionAlert] = useState(false);
  const navigate = useNavigate();

  const handlePrivacyPolicyClick = () => {
    navigate('/policy'); // Update this path to match your privacy policy route
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setSubscriptionAlert(true);
    //window.alert("Thanks for subscribing to our newsletter!");
    try {
      // const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/public/subscribeNewsletter`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email }),
        }
      );
      if (!response.ok) {
        console.warn("Something wrong");
        return;
      }
    } catch (error) {
      console.error("Error", error);
    }
  }
  function settingEmail(e) {
    //check if email is correct has text, an @ , etc
    setEmail(e.target.value);
  }

  return (<> 
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="mx-auto max-w-7xl px-6 pb-4  lg:px-8 ">
        <div className="mt-4 border-t border-white/10 pt-4 sm:mt-2 lg:mt-2 lg:flex lg:items-center lg:justify-between">
          <div className="mt-4  pt-8 md:flex md:items-center md:justify-between">
            <p className="text-2xl font-bold leading-10 text-white">
              We love hearing from you! Have feedback or questions?{" "}
              <span className="mt-2 text-lg leading-6 text-gray-300">
                Drop us a line at{" "}
                <a
                  href="mailto: contact@mytalemaker.com"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  contact@mytalemaker.com
                </a>{" "}
              </span>
            </p>
            {/*      <div className="flex space-x-6 md:order-2">
                        {social.map((item) => (
                            <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                                <span className="sr-only">{item.name}</span>
                                <item.icon className="h-6 w-6" aria-hidden="true" />
                            </a>
                        ))}
                        </div> */}
          </div>
        </div>
        <div className="mt-4  pt-8 sm:mt-4 lg:mt-4 lg:flex lg:items-center lg:justify-between">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-white">
              Subscribe to our newsletter
            </h3>
            <p className="mt-2 text-sm leading-6 text-gray-300">
              Stay in the loop with our latest news and exclusive offers
              delivered directly to your inbox!
            </p>
          </div>
          <form
            className="mt-6 sm:flex sm:max-w-md lg:mt-0"
            onSubmit={handleSubmit}
          >
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              value={email}
              autoComplete="email"
              required
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              placeholder="Enter your email"
              onChange={settingEmail}
            />
            <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
              <button
                type="submit"
                className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>

        <div className="mt-4 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
              Follow us at:
              {social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </p>
          </div>
          <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
          <span>❤️ Created by a mom, loved by families</span>
        {/*}    <span>Made with love by a toddler's mom!</span> */}
          </p>
          <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
            <button
              onClick={handlePrivacyPolicyClick}
              className="text-gray-500 hover:text-gray-400"
            >
              Terms of Use & Privacy Policy
            </button>
          </p>
          {/*}     <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0"><span> Plynto, LLC </span></p> */}
        </div>
      </div>
    </footer>
    {subscriptionAlert && (
        <SubscriptionAlert
          onConfirm={() => setSubscriptionAlert(false)} // Just close the dialog on confirm
        />
      )}
    </>
  );
}
