import { UseUserAuth } from "./context/UseAuthContext";
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { CurrencyDollarIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";

export default function Account() {
  const { user } = UseUserAuth();
  console.log("user-acc",user)
  const [credits, setCredits] = useState(0);
  const emailVerified = user.emailVerified;
  //console.log("acc-path", `${process.env.REACT_APP_BACKEND}/user/getNumCredits`)
  
   // Function to update email verification status on the backend
   const updateEmailVerified = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/usersEmailVerificationInfo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ emailVerified : emailVerified }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update email verification status");
      } else {
        console.log("Email verification status updated successfully");
        localStorage.setItem('emailVerifiedStatus', 'true');  // Store in localStorage
      }
    } catch (error) {
      console.error("Error updating email verification status:", error);
    }
  };

  // Function to fetch credit information from the backend
  const fetchCreditInfo = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/getNumCredits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
        }
      );
      if (response.ok) {
        const userCredits = await response.json();
        setCredits(userCredits);
      } else {
        console.error("Failed to fetch credit information");
      }
    } catch (error) {
      console.error("Error fetching credit information:", error);
    }
  };

  useEffect(() => {
    const checkAndFetchCredits = async () => {
      if (user) {
        const hasUpdatedEmailVerified = localStorage.getItem('emailVerifiedStatus') === 'true';
        console.log("hasUpdatedEmailVerified - acc", hasUpdatedEmailVerified);
        if (emailVerified && !hasUpdatedEmailVerified) {
          console.log("updating acc")
          await updateEmailVerified();
          }  // Update email verification status if necessary
          await fetchCreditInfo();  // Fetch credit information
      }
    };

    checkAndFetchCredits();
  }, [user, emailVerified]);

  // Conditional rendering based on email verification status
  if (!emailVerified) {
    return (
      <div>
        <PageNav className="absolute inset-x-0 top-0 z-50" />
        <Heading />
        <div className="flex justify-center bg-white px-6 py-10 lg:px-8">
          <div
            role="list"
            className="mt-8 max-w-2xl space-y-8 text-gray-800 text-justify"
          >
            <div className="flex gap-x-3">
              <ExclamationCircleIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                Please verify your email address first. An email verification link has been sent to your email. If you have verified your
                email, please refresh this page.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  

  return (
    <>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      <Heading />
      {credits > 0 ? (
        <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
          <ul
            role="list"
            className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
          >
            <li className="flex gap-x-3">
              <CurrencyDollarIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Current Credit Status:{" "}
                </strong>
                Your available credit balance is {credits || 0} credits. Please proceed by clicking on the
                <NavLink
                to="/create"
                //className="bg-indigo-600 text-white px-4 py-2 rounded font-semibold hover:bg-indigo-700 hover:text-white"
                className="font-semibold text-gray-700 hover:text-gray-500 underline"
              > create page </NavLink> to create your custom book.
              </span>
            </li>
          </ul>
        </div>
      ) : (
        <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
          <ul
            role="list"
            className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
          >
            <li className="flex gap-x-3">
              <CurrencyDollarIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Current Credit Status:{" "}
                </strong>
                {" "}Your available credit balance is {credits || 0} credit. Please
                proceed by clicking on the{" "}
                <NavLink
                  to={`/?section=pricing`}
                  className="font-semibold text-gray-700 hover:text-gray-500 underline"
                >
                  pricing page
                </NavLink>{" "} to buy credits.
              </span>
            </li>
          </ul>
        </div>
      )}
   
    </>
  );
}

function Heading() {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }

    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};

useEffect(() => {
  if (user) {
    fetchUserName();
  }
}, [user]);

return (
  <div className="flex justify-center text-lg font-semibold p-4 box mt-3 text-center text-indigo-600">
    Welcome <br />
    {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
  </div>
);
}
